import React from "react"
import Layout from "../../components/Layout";
import Hero from "../../components/ui/Hero/Hero";
import { graphql, Link, useStaticQuery } from "gatsby";
import ProductListing from "../../components/lists/ProductListing";
import HorizontalScroll from "../../components/ui/HorizontalScroll/HorizontalScroll";
import AufkleberIcon from "../../assets/aufkleber.inline.svg";
import SchilderIcon from "../../assets/schilder.inline.svg";
import TextilienIcon from "../../assets/textilien.inline.svg";
import WerbetechnikIcon from "../../assets/werbetechnik.inline.svg";
import ZubehoerIcon from "../../assets/zubehoer.inline.svg";
import * as styles from "./index.module.scss";
import InlineBreaker from "../../components/lists/InlineBreaker/InlineBreaker";

export default () =>{

    const inlineBreakers = useStaticQuery(graphql`{
  allProduktListenStoererHJson(
    filter: {anzeige: {elemMatch: {pfad: {eq: "produkte"}}}}
  ) {
    edges {
      node {
        button {
          link
          text
        }
        kurzbeschreibung
        title
        icon {
          alt
          ratio
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        anzeige {
          pfad
          positionen
        }
      }
    }
  }
}
`);


    return (
    <Layout 
        breadcrumbColor="light"
        headerColor="dark-blue" 
        hasWave={false}
        crumbs={[{ label: "Home", url: "" }, { label: "Produkte", url: "produkte" }]}
    >

        <Hero
            color="dark-blue"
            hasWaveBottom={["mobile"]}
        >
            <div className="columns">
                <div className="column is-1 is-hidden-touch"></div>
                <div className="column">

                    <div className="columns">
                        <div className="column  is-half is-one-third-widescreen">
                            <h1 className="title">Unsere Produkte </h1>
                        </div>
                        <div className="column">
                            <span className="subtitle">
                                Stöbern Sie hier unsere verschiedene Produkttypen. Wir beliefern gerne Ihr Unternehmen mit bestehenden Produkten aber auch für Individuelle,
                                spezialisierte Artikel sind wir der ideale Partner.
                            </span>
                        </div>
                    </div>

                    <div className="column">

                    <HorizontalScroll breakpoints={["mobile"]} itemsOnWidth={2}>
                        <div className="columns is-mobile">
                            <div className="column is-one-fifth">
                                <Link to="/produkte/schilder" className={styles.link}>
                                    <SchilderIcon />
                                    <span className="is-size-4">Schilder</span>
                                </Link>
                            </div>
                            <div className="column is-one-fifth">
                                <Link to="/produkte/aufkleber" className={styles.link}>
                                    <AufkleberIcon />
                                    <span className="is-size-4">Aufkleber</span>
                                </Link>
                            </div>
                            <div className="column is-one-fifth">
                                <Link to="/produkte/textilien" className={styles.link}>
                                    <TextilienIcon />
                                    <span className="is-size-4">Textilien</span>
                                </Link>
                            </div>
                            <div className="column is-one-fifth">
                                <Link to="/produkte/werbetechnik" className={styles.link}>
                                    <WerbetechnikIcon />
                                    <span className="is-size-4">Werbe&shy;technik</span>
                                </Link>
                            </div>
                            <div className="column is-one-fifth">
                                <Link to="/produkte/zubehoer" className={styles.link}>
                                    <ZubehoerIcon />
                                    <span className="is-size-4">Zubehör</span>
                                </Link>
                            </div>
                        </div>
                    </HorizontalScroll>


                    </div>

                </div>
                
                <div className="column is-1 is-hidden-touch"></div>
            </div>

            
        </Hero>

        <ProductListing
            inlineBreaker={inlineBreakers.allProduktListenStoererHJson.edges.map(({node}) => (
                {
                    element: <InlineBreaker 
                        to={node.button.link} 
                        buttonText={node.button.text} 
                        title={node.title} 
                        kurzbeschreibung={node.kurzbeschreibung} 
                        icon={{src: node.icon.src, alt: node.icon.alt, ratio: node.icon.ratio}} 
                    />,
                    colspan: 2,
                    position: node.anzeige.filter(item => item.pfad === "produkte")[0].positionen,
                }))
            }
        />
        

    </Layout>
)}